/* src/assets/styles/Footer.css */

.footer {
  color: #fff;
  background: linear-gradient(
    135deg,
    var(--secondary-color),
    var(--primary-color)
  );
}

.footer-top {
  padding: 0;
}

.footer-top-container {
  display: flex;
  justify-content: space-between;
}

.footer .footer-top .footer-top-container {
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.footer .footer-bottom .footer-bottom-container {
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.footer-about {
  flex: 0 0 50%;
  margin: 0 20px;
}

.footer-links,
.footer-contact {
  flex: 0 0 25%;
  margin: 0 20px;
}

.footer-about h2 {
  font-family: "Pacifico", cursive;
  font-size: 2em;
  color: aqua;
}

.footer-about p {
  line-height: 1.6;
}

.social-media a {
  color: #fff;
  margin-right: 15px;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: var(--accent-color);
}

.footer-links h3,
.footer-contact h3 {
  color: aqua;
}

.footer-links ul,
.footer-contact ul {
  list-style: none;
  padding: 0;
}

.footer-links li,
.footer-contact li {
  display: flex;
  align-items: center;
}

.footer-links a {
  color: #fff;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--accent-color);
}

.footer-contact i {
  margin-right: 10px;
  color: var(--accent-color);
}

.footer-bottom {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0;
  text-align: center;
}

.footer-bottom-container p {
  margin: 0;
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .footer-top-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-about,
  .footer-links,
  .footer-contact {
    text-align: center;
  }

  .footer-contact li {
    justify-content: center;
  }
}
