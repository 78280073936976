.hero {
  position: relative;
  background-image: url('../images/hero-bg.jpg');
  background-size: cover;
  background-position: center;
  height: 90vh;
  color: #fff;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.7);
  display: flex;
  align-items: center;
}

.hero-container {
  text-align: center;
}

.hero-container h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.hero-container p {
  font-size: 1.5em;
  margin-bottom: 40px;
}

.hero-btn {
  font-size: 1.2em;
}
