/* src/assets/styles/Contact.css */

.contact-page {
  padding: 80px 0;
  background-color: #f9f9f9;
}

.contact-page h1 {
  text-align: center;
  margin-bottom: 10px;
  color: var(--secondary-color);
  font-size: 2.5em;
}

.section-subtitle {
  text-align: center;
  font-size: 1.2em;
  color: #666;
  margin-bottom: 60px;
}
