.services-overview {
  padding: 80px 0;
  text-align: center;
}

.services-overview h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.section-subtitle {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 60px;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
