/* src/assets/styles/App.css */

/* Define CSS Variables */
:root {
  --primary-color: #ff6f61; /* Vibrant Coral */
  --primary-color-hover: #e65b50; /* Slightly Darker Coral */
  --secondary-color: #3b3b98; /* Deep Blue */
  --accent-color: #f1c40f; /* Bright Yellow */
  --background-color: #f9f9f9; /* Light Grey */
  --text-color: #333333; /* Dark Grey */
  --navbar-height: 80px;
  --overlay-color: rgba(59, 59, 152, 0.8); /* Semi-transparent Deep Blue */
}

/* Apply Google Fonts */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

/* Main Content Wrapper */
.main-content {
  min-height: calc(100vh - var(--navbar-height) - 100px); /* Adjust based on Navbar and Footer height */
}

/* Link Styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  color: var(--secondary-color);
}

/* Button Styles */
.btn {
  display: inline-block;
  padding: 12px 25px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 30px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-weight: 600;
}

.btn:hover {
  background-color: var(--primary-color-hover);
  transform: scale(1.05);
}

/* Container */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Responsive Typography */
@media (max-width: 768px) {
  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.75em;
  }

  .btn {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}
