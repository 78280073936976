/* src/assets/styles/ServiceCard.css */

.service-card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.service-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-card:hover .service-image {
  transform: scale(1.05);
}

.service-content {
  padding: 20px;
  transition: background-color 0.3s ease;
}

.service-content h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
  color: var(--secondary-color);
  transition: color 0.3s ease;
}

.service-content p {
  color: #555;
  line-height: 1.6;
  font-size: 1em;
}

/* Optional: Change text color on hover */
.service-card:hover .service-content h3 {
  color: var(--primary-color);
}
