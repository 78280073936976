.services-page {
  padding: 80px 0;
}

.services-page h1 {
  text-align: center;
  margin-bottom: 10px;
}

.section-subtitle {
  text-align: center;
  font-size: 1.2em;
  color: #666;
  margin-bottom: 60px;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}
