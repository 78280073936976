/* src/assets/styles/Navbar.css */

.navbar {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo a {
  display: flex;
  align-items: center;
  font-family: 'Pacifico', cursive; /* Artistic Font for Logo */
  font-size: 1.8em;
  color: #fff;
  transition: color 0.3s ease;
}

.logo a:hover {
  color: var(--accent-color);
}

.logo-image {
  width: auto;
  height: 60px;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.logo a:hover .logo-image {
  transform: rotate(360deg);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-links a {
  color: #fff;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-links a::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: var(--accent-color);
  left: 0;
  bottom: -5px;
  transition: width 0.3s ease;
}

.nav-links a:hover::after {
  width: 100%;
}

.nav-links a:hover {
  transform: translateY(-5px);
  color: var(--accent-color);
}

.btn {
  background-color: var(--accent-color);
  color: var(--secondary-color);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  padding: 10px 20px;
  border-radius: 30px;
}

.btn:hover {
  background-color: #d4ac0d;
  transform: scale(1.05);
}

.menu-icon {
  display: none;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.menu-icon:hover {
  color: var(--accent-color);
  transform: rotate(90deg);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    top: var(--navbar-height);
    right: -100%;
    flex-direction: column;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    width: 100%;
    height: calc(100vh - var(--navbar-height));
    padding-top: 40px;
    transition: right 0.3s ease;
    align-items: center;
  }

  .nav-links.open {
    right: 0;
  }

  .nav-links a {
    margin: 20px 0;
    font-size: 1.5em;
  }

  .menu-icon {
    display: block;
  }
}
