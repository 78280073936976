.about-page {
  position: relative;
  background-image: url('../images/about-bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: #fff;
}

.about-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.8); /* Semi-transparent overlay for readability */
  display: flex;
  align-items: center;
}

.about-container {
  text-align: center;
  padding: 0 20px;
}

.about-container h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #fff;
}

.about-container p {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #f0f0f0;
}

@media (max-width: 768px) {
  .about-container h1 {
    font-size: 2.5em;
  }

  .about-container p {
    font-size: 1em;
  }
}
