/* src/assets/styles/ContactForm.css */

.contact-form {
  background-color: #fff;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 40px;
  color: var(--secondary-color);
  font-size: 2em;
}

.contact-form label {
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.contact-form label input,
.contact-form label textarea {
  width: 100%;
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  color: #333;
  transition: border-color 0.3s ease;
  outline: none;
}

.contact-form label input:focus,
.contact-form label textarea:focus {
  border-color: var(--primary-color);
}

.contact-form label textarea {
  resize: vertical;
  min-height: 150px;
}

.contact-form button {
  width: 100%;
  padding: 15px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form button:hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-3px);
}

.status {
  margin-top: 20px;
  color: green;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 768px) {
  .contact-form {
    padding: 40px 20px;
  }

  .contact-form h2 {
    font-size: 1.8em;
  }
}
